import React from 'react'
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Background, Parallax } from "react-parallax"
import { Grid, Row, Col } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql, Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import TitleHeader from "../components/title-header"

const CompanyPage = ({data}) => (
  <Layout>
    <div>
      <SEO title="Company | Korecki Real Estate Services" description="We are licensed through the Real Estate Council of B.C., and believe in providing personalized service, ongoing communication and maintaining strong working relationships with our clients" keywords="korecki,Real Estate,Strata Management services, Alex Korecki, Vesna Korecki, licensed , Client Property Account System"/>
			<TitleHeader title={"Corporate Profile"}/>
    				<Grid fluid className="half-section-container feature-section">
								<Col md={6} className="half-section">
									<div className="parallax-half-section">
										<LazyLoadImage
											alt="banner"
											height={690}
											style={{ objectFit: "cover" }}
											width="100%"
											src={data.contentfulCorporateProfile.image.file.url}
										/>
									</div>
								</Col>
								<Col md={6} className="half-section blocktext" style={{ backgroundColor: 'rgba(250,250,250,0)' }}>
									<Row className="heading">
										<Col md={12}>
											<h2 className="no-p">{data.contentfulCorporateProfile.title}</h2>
										</Col>
									</Row>
									<Row>
										<Col className="custom-text-color-children" md={12}>
											{documentToReactComponents(data.contentfulCorporateProfile.profile.json)}
										</Col>
									</Row>
									<section>
									<Row className="center">
										<a className="custom-icons" href="https://www.bcfsa.ca/" target="_blank" rel="noreferrer"><img alt="BC Financial Services Authority" className="bcfsa-logo" src={require('../images/bcfsa.png')} /></a>
										<a className="custom-icons" href="https://www.pama.ca" target="_blank" rel="noreferrer"><img alt="Professional Association of Managing Agents" src={require('../images/pama.png')} /></a>
										<a className="custom-icons" href="https://www.rebgv.org" target="_blank" rel="noreferrer"><img alt="Real Estate Board of Greater Vancouver" src={require('../images/rebgv.png')} /></a>
									</Row>
										<br />
									<Row className="center">
										<a className="custom-icons" href="https://spabc.org" target="_blank" rel="noreferrer"><img alt="Strata Property Agents of BC" src={require('../images/spabc.png')} /></a>
										<a className="custom-icons" href="https://www.choa.bc.ca/" target="_blank" rel="noreferrer"><img alt="Condominium Homeowners Association of BC" src={require('../images/choa.png')} /></a>
										<a className="custom-icons" href="https://udi.bc.ca/" target="_blank" rel="noreferrer"><img alt="Urban Development Institute" src={require('../images/UDI-13.png')} /></a>
									</Row>
									</section>
								</Col>
    				</Grid>
        </div>
    </Layout>
)

export const query = graphql`
  {
      contentfulCorporateProfile(title: {eq: "Corporate Profile"}) {
      title
      profile {
        json
      }
      image {
      	file {
      		url
      	}
      }
    }
  }`
export default CompanyPage;
